import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";

import SearchBlock from "components/search/SearchBlock";
import { ApolloProvider, Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { autobind } from "react-decoration";
import { page } from "components/page";
import { navigate } from "gatsby";
import Link from "lib/Link";

const getFaqDetail = gql`
  query getFaqDetail($form: FaqForm) {
    result: getFaqDetail(Input: $form) {
      sn
      quest
      answer
      urls
      creTime
      updTime
      attachs{
        attachName
        showName
        attachPath
      }
    }
  }
`;

@withI18next(["common"])
@page
class FaqDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resourceType: "databases",
      pageContent : ""
    };

  }

  
  render() {
    let { t } = this.props;
    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <a class="accesskey" href="#aC" id="aC" accesskey="C" title="主要內容區">:::</a>
          <SearchBlock />
          <section className="main_content">
            <div className="container">

            <h2>{t("jumper.common.faq")}</h2>
            <a href="javascript:history.back();" className="back" >{this.props.t("hyint.common.table.back")}</a>

            <ApolloProvider client={client.jumperrwdClient}>
              <Query
                query={getFaqDetail}
                variables={{
                  form: {
                    id : this.props.search.sn
                  }
                }}
              >

                {({ loading, data, refetch, error }) => {
                  if (error) return "";
                  if (loading) return "Loading...";

                  if (data != null) {
                    let quest =
                    t("auto.ws_question.quest." + data.result.sn) !==
                    "auto.ws_question.quest." + data.result.sn
                      ? t("auto.ws_question.quest." + data.result.sn)
                      : data.result.quest;
                    return(
                      <>
                        <div className="detail_block">
                          <h3>{quest}</h3>
                          <div className="date_panel">
                              <div className="publish_time">
                                  <span>{this.props.t("hyint.common.table.credate")}</span><time>{data.result.creTime}</time>
                              </div>
                              <div className="publish_time">
                                  <span>{this.props.t("hyint.common.table.upddate")}</span><time>{data.result.updTime}</time>
                              </div>
                          </div>
                          <div className="article_content">
                              <p dangerouslySetInnerHTML={{__html: data.result.answer}}></p>
                              
                              {data.result.urls && 
                                <p>{this.props.t("hyint.common.table.relurl")}：
                                {data.result.urls.split('\n').map((items, i) => {
                                  let data = items.split(";")
                                  if (data[0]=="1") {
                                    return (<p><Link href={data[2]} target="_blank">{data[1]}</Link></p>) 
                                  }else{
                                    return (<p><Link href={data[2]} >{data[1]}</Link></p>) 
                                  }
                                })}
                                </p>
                              }
                              {data.result.attachs && data.result.attachs.length>0 &&
                                <p>{this.props.t("hyint.admin.common.table.reffile")}：
                                {data.result.attachs.map((item, i) => {
                                  return (<p><Link href={item.attachPath}>{item.showName}</Link></p>) 
                                })}
                                </p>
                              }

                          </div>
                        </div>
                      </>
                      );
                  }else{
                    return "";
                  }
                }}

              </Query> 
            </ApolloProvider>

            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default FaqDetail;
